import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Key Facts `}</strong></p>
        <ul>
          <li parentName="ul">{`volatility-targeting strategy`}</li>
          <li parentName="ul">{`leveraged to 200% exposure`}</li>
          <li parentName="ul">{`invests in ETFs tracking stocks, treasuries, gold, and VIX futures`}</li>
          <li parentName="ul">{`rebalances weekly`}</li>
        </ul>
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mach-2/"
            }}>{`Mach-2`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This portfolio is `}<strong parentName="p">{`outdated`}</strong>{` and requires improvements to better cope with environments of rising bond yields. In the meantime, we suggest using `}<a parentName="p" {...{
            "href": "/portfolios/tt-mach-2/"
          }}>{`Mach-2`}</a>{` as a replacement.`}</p>
      </Border><br />
      <p><em parentName="p">{`Dos Equis`}</em>{` is a proprietary premium strategy by `}<em parentName="p">{`TuringTrader.com`}</em>{`, introduced in August 2020.`}</p>
      <p><em parentName="p">{`Dos Equis`}</em>{` aims to continuously beat the S&P 500, but avoid most of the benchmark's downturns. To achieve this objective, the strategy invests in 2x leveraged ETFs representing the S&P 500, U.S. Treasuries, and gold. In times of elevated volatility, `}<em parentName="p">{`Dos Equis`}</em>{` reduces its allocation to risky assets, while adding a hedge of up to 15% exposure to VIX futures. This hedge protects the portfolio against sudden increases in volatility often observed during rapid market sell-offs.`}</p>
      <p><em parentName="p">{`Dos Equis`}</em>{` rebalances weekly, typically adjusting the weights of only three ETFs. This schedule equates to quite low maintenance requirements, compatible with a busy lifestyle.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Dos Equis: A volatility-targeting strategy with 2x leverage",
                "title": "TuringTrader.com, Dos Equis: A volatility-targeting strategy with 2x leverage",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/leveraged-etf-portfolios/"
            }}>{`Leveraged ETF Portfolios`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/volatility-targeting/"
            }}>{`Volatility Targeting`}</a></li>
        </ul>
      </Aside>
      <h3>{`Strategy Rules`}</h3>
      <p>{`The operation of `}<em parentName="p">{`Dos Equis`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`start with a static allocation of 2x leveraged ETFs: 54% U.S. Stocks, 38% U.S. Treasuries, 8% gold`}</li>
        <li parentName="ul">{`adjust allocations based on the individual asset's 1-month volatility`}</li>
        <li parentName="ul">{`in times of high volatility, allocate up to 15% to VIX futures, based on a VIX model`}</li>
        <li parentName="ul">{`allocate the remaining capital to U.S. Treasuries with intermediate maturities`}</li>
        <li parentName="ul">{`rebalance weekly`}</li>
      </ul>
      <p><em parentName="p">{`Dos Equis`}</em>{` starts with a static asset allocation to provide some inherent portfolio stability and reduce the requirements for active management. This step helps compensate for the amplified volatility of the leveraged ETFs. Market sell-offs most often coincide with elevated volatility. Consequently, `}<em parentName="p">{`Dos Equis`}</em>{` manages risk by scaling its positions based on the asset's volatility. Based on a simple VIX forecasting model, `}<em parentName="p">{`Dos Equis`}</em>{` takes an additional long position in VIX futures to hedge for market risks.`}</p>
      <p>{`To learn more about the rationale behind `}<em parentName="p">{`Dos Equis`}</em>{`, read our article regarding aggressively leveraged ETF portfolios.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Dos Equis`}</em>{` diversifies across three major asset classes, plus VIX futures for an additional hedge. This diversification manifests itself in a beta of around 0.3. Even in bullish periods, the portfolio does not invest much more than 60% in the 2x leveraged S&P 500. This scheme keeps the portfolio balanced and well-mannered.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Dos Equis`}</em>{` beats the S&P 500 benchmark in most years but shines in times of spiking volatility. The static asset allocation, including treasuries and gold, provides some level of protection against unforeseen events. Volatility-targeting plus the use of VIX futures as a volatility hedge help dampen portfolio volatility and avoid deep drawdowns.`}</p>
      <p>{`As a result, `}<em parentName="p">{`Dos Equis`}</em>{` shows about 25% lower volatility, and about 50% lower downside than the S&P 500 benchmark while, over the full economic cycle, delivering approximately twice the return.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Dos Equis`}</em>{` trades frequently and regularly triggers taxable events. However, the changes made to the asset allocation are typically small. Outside of significant volatility events, a large percentage of the assets is held for more than 12 months, with capital gains qualifying for long-term treatment. While the overall tax burden is certainly higher than for a passive portfolio, the substantially higher long-term returns should outweigh this disadvantage.`}</p>
      <p><em parentName="p">{`Dos Equis`}</em>{` makes use of 2x leveraged ETFs, as well as volatility-linked ETPs, which are considered high-risk instruments. Many brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. Because we use these products in tandem with inversely correlated assets, we believe our use of these instruments to be responsible.`}</p>
      <p><em parentName="p">{`Dos Equis`}</em>{` invests in no more than five ETFs at a time. The strategy should function as intended with as little as $10,000 of capital.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, August 2020`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      